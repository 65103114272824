import type { FormEvent, JSX } from 'react'
import { browserHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

import translate, { withComponents } from '../../../../utils/translate'
import {
  assignCustomerToCart,
  loadCart,
  loginCustomer,
  logoutCustomer,
  signupCustomer,
} from '../../../../store/actions'
import {
  generateCustomerAccountUrl,
  generateCustomerOrdersUrl,
  generateShopUrl,
  withQueryAndScope,
} from '../../../../urlGenerators'
import { getPlain } from '../../../../store/utils'
import { track } from '../../../../utils/tracking'
import Dropdown from '../Dropdown'
import Lightbox from '../../../templateComponents/Lightbox'
import Link from '../../../templateComponents/Link'
import PasswordRevealInput from '../PasswordRevealInput'
import Portal from '../../../templateComponents/Portal'
import useAutoFocus from '../../../../utils/hooks/useAutoFocus'

const customerAccountPath = generateCustomerAccountUrl()
const customerOrdersPath = generateCustomerOrdersUrl()

export default translate('components.accountMenuComponent')(AccountMenu)

function AccountMenu({ t }: TranslateProps): JSX.Element {
  const dispatch: ApiActionDispatch = useDispatch<GlobalDispatch>()

  const isLoggedIn = useSelector<State, boolean>((state) => state.getIn(['customer', 'loggedIn'], false))
  const location = useSelector<State, ImmutableMap>((state) => state.get('location'))

  const [showForm, setShowForm] = useState<null | 'login' | 'signup'>(null)
  const hideForm = () => setShowForm(null)

  // enable deep linking to the login form
  useEffect(() => {
    if (location.hasIn(['query', 'login'])) setShowForm('login')
    if (new URL(window.location.href).hash.includes('showLoginForm')) setShowForm('login')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  async function handleLogout() {
    try {
      await dispatch(logoutCustomer())
      if (location.get('pathname').startsWith(customerAccountPath)) {
        browserHistory.push(withQueryAndScope(generateShopUrl(), location))
      }
    } catch {
      // global API call error handler takes care
    }
  }

  return (
    <div className="header-account-login">
      <Dropdown
        ariaId="account-dropdown"
        ariaLabel={t('accountIcon.accessibilityLabel')}
        buttonLabel={<span className="header-account-icon" />}
      >
        {(close: () => void) =>
          isLoggedIn ? (
            <>
              <li>
                <Link to={customerAccountPath} onClick={close} activeClassName={'active-link'}>
                  {t('accountLink.label')}
                </Link>
              </li>
              <li>
                <Link to={customerOrdersPath} onClick={close} activeClassName={'active-link'}>
                  {t('ordersLink.label')}
                </Link>
              </li>
              <hr></hr>
              <li>
                <button onClick={handleLogout} className="logout-button">
                  {t('logoutButton.label')}
                </button>
              </li>
            </>
          ) : (
            <>
              <li>
                <button onClick={() => setShowForm('login')}>{t('loginButton.label')}</button>
              </li>
              <li>
                <button onClick={() => setShowForm('signup')}>{t('signupButton.label')}</button>
              </li>
            </>
          )
        }
      </Dropdown>
      <Portal>
        <Lightbox show={Boolean(showForm)} onClose={hideForm} lightboxClassName="lightbox-modal">
          {showForm === 'login' && <LoginForm onClose={hideForm} onGoToSignup={() => setShowForm('signup')} />}
          {showForm === 'signup' && <SignupForm onClose={hideForm} onGoToLogin={() => setShowForm('login')} />}
        </Lightbox>
      </Portal>
    </div>
  )
}

type LegalPage = {
  slug: string
  href: string
  title: string
}

type LegalPageLinkProps = {
  slug: string
  newTab?: boolean
} & TranslateProps

export const LegalPageLink = translate()(({
  t,
  slug: chosenSlug,
  newTab,
}: Readonly<LegalPageLinkProps>): JSX.Element => {
  const legalPages = getPlain(useSelector<State, LegalPage[]>((state) => state.get('legalPages')))
  const location = useSelector<State, ImmutableMap>((state) => state.get('location'))

  const { href, title } = legalPages.find(({ slug }) => slug === chosenSlug) as LegalPage

  return (
    <Link href={withQueryAndScope(href, location)} {...(newTab ? { target: '_blank' } : {})}>
      {t(title)}
    </Link>
  )
})

type LoginFormProps = {
  onClose: () => void
  onGoToSignup: () => void
} & TranslateProps

export const toggleLabelInvalidClass =
  (invalid: boolean) =>
  (eventOrElement: FormEvent<HTMLFormElement> | HTMLFormElement): void => {
    const element = (eventOrElement?.target || eventOrElement) as HTMLInputElement
    element?.closest('label')?.classList[invalid ? 'add' : 'remove']('form-element-invalid')
  }

const LoginForm = translate('components.accountMenuComponent.loginModal')(({
  t,
  onClose,
  onGoToSignup,
}: Readonly<LoginFormProps>): JSX.Element => {
  const cart = getPlain(useSelector<State, Core.Cart | null>((state) => state.get('cart', null)))

  const { confirmedNewEmail } =
    getPlain(useSelector<State, Record<string, any>>((state) => state.get('customer'))) || {}

  const location = useSelector<State, ImmutableMap>((state) => state.get('location'))
  const dispatch = useDispatch()

  const [error, setError] = useState<string | null>(null)
  const [submitting, setSubmitting] = useState(false)

  const form = useRef<HTMLFormElement>(null)
  useAutoFocus(form)

  async function handleSubmit(event) {
    event.preventDefault()
    setSubmitting(true)

    const email = event.target.elements.email.value
    const password = event.target.elements.password.value

    try {
      setError(null)
      await dispatch(loginCustomer({ email, password }, { showErrorNotification: false }))

      if (cart?.productLineItems?.length) await dispatch(assignCustomerToCart())
      else await dispatch(loadCart())

      setSubmitting(false)
      onClose()

      track('customer:signIn', { customer: { email } })

      const redirectTarget = new URL(window.location.href).searchParams.get('redirect') || ''
      const destination = decodeURIComponent(redirectTarget) || customerAccountPath
      browserHistory.push(withQueryAndScope(destination, location))
    } catch (error) {
      // scroll up to the error message
      form.current?.scrollIntoView(true)

      if (['unauthorized', 'invalid_grant'].includes(error.message)) {
        setError(t('errors.credentialsInvalid'))
        setSubmitting(false)

        // UX concept wants inputs marked as invalid upon wrong credentials
        toggleLabelInvalidClass(true)(form.current?.email)
        toggleLabelInvalidClass(true)(form.current?.password)
      } else {
        setError(t('errors.genericServerError'))
      }
    }
  }

  return (
    <form
      ref={form}
      className="lightbox-modal-form account-login-form"
      onInvalid={toggleLabelInvalidClass(true)}
      onInput={toggleLabelInvalidClass(false)}
      name="customerLogin"
      onSubmit={handleSubmit}
      autoComplete="on"
    >
      <div className="inner-content">
        <h2 className="title">{t('title')}</h2>
        <p className="description">{t('explanation')}</p>
        {error && <p className="error-message">{error}</p>}
        {confirmedNewEmail && <p className="warning-message">{t('notifications.confirmedNewEmail')}</p>}
        <label>
          {t('emailInputField.label')}*
          <input
            name="email"
            autoComplete="email"
            defaultValue={confirmedNewEmail}
            className="ep-form-text-field"
            type="email"
            required
          />
        </label>
        <label>
          {t('passwordInputField.label')}*
          <PasswordRevealInput name="password" autoComplete="password" className="ep-form-text-field" required />
        </label>
        <Link className="forgot-password-link" to="/forgot-password">
          {t('resetPasswordLink.label')}
        </Link>
      </div>
      <div className="button-wrapper">
        <button className="button button-primary button-pending-state pending" type="submit" disabled={submitting}>
          {t('continueButton.label')}
        </button>
        <button className="button button-outline" type="button" onClick={() => onGoToSignup()}>
          {t('signupButton.label')}
        </button>
      </div>
      <aside>
        {withComponents(t)('legalPageLinks', {
          tac: <LegalPageLink newTab slug="tac" />,
          privacy: <LegalPageLink newTab slug="privacy" />,
        })}
      </aside>
    </form>
  )
})

type SignupFormProps = {
  onClose: () => void
  onGoToLogin: () => void
} & TranslateProps

const SignupForm = translate('components.accountMenuComponent.signupModal')(({
  t,
  onClose,
  onGoToLogin,
}: Readonly<SignupFormProps>): JSX.Element => {
  const location = useSelector<State, ImmutableMap>((state) => state.get('location'))
  const cart = getPlain(useSelector<State, Core.Cart | null>((state) => state.get('cart', null)))

  const dispatch = useDispatch()

  const [error, setError] = useState<string | null>(null)
  const [submitting, setSubmitting] = useState(false)

  const form = useRef<HTMLFormElement>(null)
  useAutoFocus(form)

  async function handleSubmit(event) {
    event.preventDefault()
    setSubmitting(true)

    const firstName = event.target.elements.firstName.value
    const lastName = event.target.elements.lastName.value
    const email = event.target.elements.email.value
    const initialPassword = event.target.elements.initialPassword.value

    try {
      setError(null)
      await dispatch(signupCustomer({ firstName, lastName, email, initialPassword }, { showErrorNotification: false }))
      if (cart?.productLineItems?.length) await dispatch(assignCustomerToCart())

      setSubmitting(false)
      onClose()

      track('customer:signUp', { customer: { email, firstName, lastName } })

      browserHistory.push(withQueryAndScope(customerAccountPath, location))
    } catch (error) {
      // scroll up to the error message
      form.current?.scrollIntoView(true)

      if (/validation failed/.test(error.message)) {
        setError(t('errors.emailAlreadyRegistered'))
      } else {
        setError(t('errors.genericServerError'))
      }
      setSubmitting(false)
    }
  }

  return (
    <form
      ref={form}
      className="lightbox-modal-form account-signup-form"
      onInvalid={toggleLabelInvalidClass(true)}
      onInput={toggleLabelInvalidClass(false)}
      name="customerSignup"
      onSubmit={handleSubmit}
      autoComplete="on"
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" onClick={() => onGoToLogin()} className="nav-link">
        {t('backToLogin')}
      </a>
      <div className="inner-content">
        <h2 className="title">{t('title')}</h2>
        {error && <p className="error-message">{error}</p>}
        <label>
          {t('firstNameInputField.label')}*
          <input name="firstName" autoComplete="given-name" className="ep-form-text-field" type="text" required />
        </label>
        <label>
          {t('lastNameInputField.label')}*
          <input name="lastName" autoComplete="family-name" className="ep-form-text-field" type="text" required />
        </label>
        <label>
          {t('emailInputField.label')}*
          <input name="email" autoComplete="email" className="ep-form-text-field" type="email" required />
        </label>
        <label>
          {t('passwordInputField.label')}*
          <PasswordRevealInput
            name="initialPassword"
            autoComplete="new-password"
            placeholder={t('passwordInputField.hintText')}
            minLength={8}
            className="ep-form-text-field"
            required
          />
        </label>
        <label className="checkbox-wrapper">
          <input type="checkbox" required />
          <div>
            {withComponents(t)('conditionsAcceptedCheckbox.label', {
              tac: <LegalPageLink slug="tac" />,
              privacy: <LegalPageLink slug="privacy" />,
            })}{' '}
            *
          </div>
        </label>
      </div>
      <div className="button-wrapper">
        <button className="button button-primary button-pending-state pending" type="submit" disabled={submitting}>
          {t('createAccountButton.label')}
        </button>
      </div>
    </form>
  )
})
